<template>
    <div class="report-box sm-qu-padding">
        <ReportComponent></ReportComponent>
    </div>
</template>
<script>
import ReportComponent from '../../components/ReportComponent.vue'
export default {
    components: {ReportComponent},
}
</script>


<style scoped>
.report-box{
    padding-left: 2.6rem;
    overflow: hidden;
}
@media screen and (max-width: 520px){
    .sm-qu-padding{
      padding: 0;
    }
  }
</style>