<template>
    <div class="rpcom-box">
        <div class="zhu-box">
            <div class="zhu-title-box">
                <div class="zhu-title zhu-title-active">{{$t('ReportBalanceTransaction.Report')}}</div>
            </div>
            <div class="zhu-card-box">
                <div class="box-card">
                    <el-card>
                        <div class="zhu-top-item">
                            <div class="zhu-left-box">
                                <i class="zhu-icon balance-icon"></i>
                            </div>
                            <div class="zhu-right-box">
                                <div class="zhu-right-title">{{$t('ReportBalanceTransaction.Item1.Title')}}</div>
                                <span class="zhu-desc">{{$t('ReportBalanceTransaction.Item1.Desc')}}</span>
                            </div>
                        </div>
                        <div class="zhu-bottom-box">
                            <el-button @click="bReport" size="small" class="zhu-btm-btn jx-btn">{{$t('ReportBalanceTransaction.Item1.ReportBtn')}}</el-button>
                        </div>
                    </el-card>
                </div>
                <div class="box-card" style="margin-left:30px">
                    <el-card>
                        <div class="zhu-top-item">
                            <div class="zhu-left-box">
                                <i class="zhu-icon card-icon"></i>
                            </div>
                            <div class="zhu-right-box">
                                <div class="zhu-right-title">{{$t('ReportBalanceTransaction.Item2.Title')}}</div>
                                <span class="zhu-desc">{{$t('ReportBalanceTransaction.Item2.Desc')}}</span>
                                <span class="zhu-desc" style="opacity:0">{{$t('ReportBalanceTransaction.Item2.Desc')}}</span>
                            </div>
                        </div>
                        <div class="zhu-bottom-box">
                            <el-button @click="transactionReport" size="small" class="zhu-btm-btn jx-btn">{{$t('ReportBalanceTransaction.Item2.ReportBtn')}}</el-button>
                        </div>
                    </el-card>
                </div>
            </div>
        </div>
        <div>
            <el-dialog
            :close-on-click-modal="false"
            :visible.sync="digVisible"
            width="35%">
            <div slot="title" class="digTitle">
                <div>{{ digTitle == 'balance' ? $t('ReportBalanceTransaction.ReportBalance') : $t('ReportBalanceTransaction.ReportCardTransaction')  }}</div>
            </div>
            <div class="dig-content-box">
                <div class="dig-con-item">
                    <div class="dig-lb">{{$t('CardDetail.AfterDate')}}</div>
                    <el-date-picker
                    style="width:190px"
                    v-model="forms.afterDate"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    :placeholder="$t('CardDetail.AfterDate')"
                  >
                  </el-date-picker>
                </div>
                <div class="dig-con-item" style="margin-left:10px">
                    <div class="dig-lb">{{$t('CardDetail.BeforeDate')}}</div>
                    <el-date-picker
                    style="width:190px"
                    v-model="forms.beforeDate"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    :placeholder="$t('CardDetail.BeforeDate')"
                  >
                  </el-date-picker>
                </div>
                
            </div>
            <!-- <div class="file-idear-box">
                    <div style="margin-bottom:10px;font-weight:bold">{{$t('ReportBalanceTransaction.FileIdear')}}</div>
                    <div>
                        <el-radio-group v-model="fileRadio">
                            <el-radio label="application/vnd.ms-excel">Excel</el-radio>
                            <el-radio label="application/pdf">PDF</el-radio>
                        </el-radio-group>
                    </div>
            </div> -->
            <span slot="footer" class="dialog-footer">
            <el-button class="jx-btn" @click="exportReport">{{$t('ReportBalanceTransaction.Export')}}</el-button>
            <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
            </span>
        </el-dialog>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {exportReport} from '../api/http'
// import axios from 'axios'
export default {
    data() {
        return {
            forms: {
                afterDate: '',
                beforeDate: ''
            },
            digVisible: false,
            digTitle: 'balance',
            fileRadio: 'application/vnd.ms-excel'
        }
    },
    methods: {
        // 显示余额流水报告弹窗
        bReport() {
            this.digVisible = true
            this.digTitle = 'balance'
        },
        // 显示交易记录报告弹窗
        transactionReport() {
            this.digVisible = true
            this.digTitle = 'card_transaction'
        },
        // 請求導出
        reqExportReport(type) {
            let ld = this.$loading({fullscreen:true})
            let data = {
                type: 'export_report',
                uid: localStorage.getItem('uid'),
                rtype: type,
                after: this.forms.afterDate,
                before: this.forms.beforeDate
            }
            // exportReport(data).then((res) => {
            //     if(res.code == 403) {
            //         this.$message.error(res.message)
            //     }
            // })
            const fdata = new FormData()
            for (let k in data) {
                fdata.append(k, data[k]);
            }
            this.$axios({
                method: 'POST',
                url: '/request/user.php',
                responseType: 'blob',
                data: fdata
            }).then((res) => {
                console.log('dc res==>', res)
                if(res.data.code == 403) {
                    ld.close()
                    this.$message.error(res.data.message)
                }
                ld.close()
                this.digVisible = false
                let url = window.URL.createObjectURL(new Blob([res.data],{type: this.fileRadio}))
                console.log('url==>', url)
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', this.digTitle + '-' + this.forms.afterDate + '-' + this.forms.beforeDate)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url)
                // this.downloadFile(url2,'report.xlsx')
                this.forms.afterDate = ''
                this.forms.beforeDate = ''
                this.fileRadio = 'application/vnd.ms-excel'
            })
        },
        downloadFile(data,fileName) {
            const reader = new FileReader()
            reader.readAsDataURL(data)
            reader.onload = (e) => {
                let ae = document.createElement('a')
                ae.download = fileName
                ae.style.display = 'none'
                let url = reader.result
                ae.href = url
                document.body.appendChild(ae)
                ae.click()
                document.body.removeChild(ae)
            }
        },
        // 導出
        exportReport() {
            if(this.forms.afterDate == '' || this.forms.beforeDate == '' || this.forms.afterDate == null || this.forms.beforeDate == null) {
                this.$message.error(this.$t('ReportBalanceTransaction.NoSelectDateTip'))
                return
            }else{
                this.reqExportReport(this.digTitle)
            }
            
        },
    }
}
</script>

<style scoped>
.rpcom-box{
    height: 100vh;
    background: #fff;
}
.zhu-title-box{
    margin-left: 30px;
    padding-top: 10px;
}
.zhu-title-active{
    font-size: 16px;
    font-weight: bold;
    border-left: 5px solid #00BEA4;
    padding-left: 2px;
}
.zhu-card-box{
    width: 800px;
    display: flex;
    align-items: center;
    padding: 20px 30px;
}
.box-card{
    width: 50%;
}
.zhu-top-item{
    display: flex;
    align-items: center;
}
.zhu-right-box{
    padding-left: 20px;
}
.zhu-right-title{
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
}
.zhu-desc{
    font-size: 13px;
}
.zhu-icon{
    width: 50px;
    height: 50px;
    display: inline-block;
    background: #f8f8f8;
    border-radius: 50%;
}
.balance-icon{
    background: url('../assets/report_balance.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.card-icon{
    background: url('../assets/report_card_transaction.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.zhu-bottom-box{
    text-align: right;
    margin-top: 10px;
}
.zhu-btm-btn{
    color: #00BEA4;
    outline: none;
}
.jx-btn:hover{
    background: #E6F9F6;
    color: #00BEA4;
    border-color: #00BEA4;
}
.jx-btn:focus{
    background: #E6F9F6;
    color: #00BEA4;
    border-color: #00BEA4;
    outline: 0;
}
.dig-content-box{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}
.dig-lb{
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}
.digTitle{
    font-weight: bold;
    font-size: 17px;
}
.file-idear-box{
    margin-top: 20px;
}
</style>